import React, { useCallback } from 'react'
import { clsx } from 'clsx'

import styles from './Main.module.css'

export const Main = () => {

  const email = 'howard@grovestreet.io'

  const techStackList = [
    'React, Redux, Flux, Reselect, Redux-Saga',
    'TypeScript, ES6, Babel',
    'Webpack, Gulp, Grunt',
    'Jest, React Testing Library, Cypress, Mocha',
    'SCSS, LESS, CSS Modules, Flexbox',
    'Node, Express, Ruby on Rails',
    'AWS, CircleCI, CDNs, Datadog, Sentry'
  ]

  const renderTechStackList = () => (
    <ul><ul>{techStackList.map(item => <li>{item}</li>)}</ul></ul>
  )

  const copyEmailToClipboard = useCallback(() => {
    navigator.clipboard.writeText(email);
  }, [])

  const renderClipboard = () => (
    <span className={styles.MainClipboard} onClick={copyEmailToClipboard}>📋</span>
  )

  return (
    <div className="row">
      <div className={clsx(styles.MainContent, 'twelve columns')}>
      <p>
        👋 , I'm Howard, a software developer based in New York City.
      </p>
      <p>
        I have nearly a decade of experience working with startups and companies across several industries: EdTech, CRM, HRTech, etc.
        <br/>
        <br/>
        Most recently, I was a Software Engineer at <a href="https://meta.com" target="_blank" rel="noreferrer">Meta</a>, and before that, a Principal Engineer at <a href="https://kustomer.com" target="_blank" rel="noreferrer">Kustomer</a>.
      </p>
      <p>
        I specialize in front-end engineering, engineering solutions, and technical strategies (especially for high-growth startups).
        Whether it's revamping your web architecture to improve web performance,
        operationally scaling out your web environments to support your perpetually-growing stakeholders,
        or some extra hands to meet your commit dates,
        I'd love to work with you to push your business and products forward.
        <br/>
        <br/>
        Recent tech stack experience:
          {renderTechStackList()}
        <br/>
        Interested in working together? Feel free to say hi at {email} {renderClipboard()} !
      </p>
      </div>
    </div>
  )
}