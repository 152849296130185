import React from 'react';
import { clsx } from 'clsx'

import { Main } from './pages/Main';

import styles from './App.module.css';

function App() {
  return (
    <div id={styles.App} className={clsx('container')}>
      <h1 className={styles.AppHeader}>
        Grove Street Technologies
      </h1>
      <Main />
    </div>
  );
}

export default App;
